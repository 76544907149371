import { useState } from "react";
import Router from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fbq from '../../lib/fpixel'
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Icon from "../Icon";

import { SearchForm, SearchInput } from "./styles";

export default () => {
  const [q, setQ] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    fbq.searchfb(q);
    
    Router.push({ pathname: "/busca", query: { q } });
  }

  return (
    <SearchForm onSubmit={handleSubmit}>
      <SearchInput
        onChange={e => setQ(e.target.value)}
        name="q"
        placeholder="Pesquisar ..."
      />
      <button type="submit">
        <Icon color="#676767" size="14">
          <FontAwesomeIcon icon={faSearch} />
        </Icon>
      </button>
    </SearchForm>
  );
};
