import { useState, useEffect } from "react";

import { ListItem, SubcategoryList } from "./styles";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";

import { useCategories } from "../CategoriesContext";

import Icon from "../Icon";

export default function CategoriesList() {
  const [categoriesList, setCategoriesList] = useState([]);
  const { categories } = useCategories();

  useEffect(() => {
    const modifiedCategories = categories.map(category => {
      return { ...category, expanded: false };
    });

    setCategoriesList(modifiedCategories);
  }, [categories]);

  const expandCategory = id => {
    const modifiedCategories = categoriesList.map(category => {
      return category.id === id
        ? { ...category, expanded: !category.expanded }
        : category;
    });

    return setCategoriesList(modifiedCategories);
  };

  return (
    <ul>
      <ListItem>
          <Link
            href={`/outlet`}
          >
            <a>
              OUTLET
            </a>
          </Link>
        </ListItem>

      {categoriesList.map(category => (
        <ListItem key={category.id}>
          <Link
            href={`/categoria/[...slug]`}
            as={`/categoria/${category.slug}`}
          >
            <a>
              {category.name}

              {(category.slug != 'bookx' && category.subcategories) && (
                <Icon
                  size="20"
                  color="#cfcfcf"
                  onClick={e => {
                    e.preventDefault();
                    return expandCategory(category.id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={category.expanded ? faMinusSquare : faPlusSquare}
                  />
                </Icon>
              )}
            </a>
          </Link>
          {(category.slug != 'bookx' && category.subcategories) && (
            <SubcategoryList expanded={category.expanded}>
              {category.subcategories.map(subcategory => (
                <ListItem key={subcategory.id}>
                  <Link
                    href={`/categoria/[...slug]`}
                    as={`/categoria/${category.slug}/${subcategory.slug}`}
                  >
                    <a>{subcategory.name}</a>
                  </Link>
                </ListItem>
              ))}
            </SubcategoryList>
          )}
        </ListItem>
      ))}
    </ul>
  );
}
