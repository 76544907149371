import { memo } from "react";
import Link from "next/link";

import Icon from "../../Icon";

import { BrandLink, CategoryList, CategoryItens, SubcategoryList, SubcategoryItem, CategoryLink } from "./styles";
function CategoryItem({ brand }) {
  if (brand.slug == 'natuzzi' || brand.slug == 'natuzzi-editions')
  {
    return ;
  }
  return (
    <li style={{listStyleType: 'none'}}>
      
      <div class="">
        <Link
          href={`/nossas-marcas/[...slug]`}
          as={`/nossas-marcas/${brand.link}`}
          passHref
        >
          <BrandLink style={{display: 'flex', justifyContent: 'space-between'}} hasCategory={(!!brand.groups && brand.name != 'bookx')}>
            <span>{brand.name}</span>
            <Icon style={{marginLeft: '17px', alignSelf: "center"}} size="21">
              <img src={brand.img_menu} alt={brand.name} />
            </Icon>
          </BrandLink>
        </Link>
        {/* {brand.groups && (
        <CategoryList style={{marginBottom: "20px"}}>
          {brand.groups.map(category => (
            <CategoryItens key={category.id} style={{marginBottom: "10px"}}>
              <Link
                href={`/categoria/[...slug]`}
                as={`/categoria/${category.slug}`}
              >
                <CategoryLink hasSubCategory={(!!brand.groups && brand.name != 'bookx')}>{category.name}</CategoryLink>
              </Link>

            </CategoryItens>
          ))}
        </CategoryList>
      )} */}
      </div>

    </li>
  );
}

export default memo(CategoryItem);
