import styled from "styled-components";

export const CTALink = styled.a`
  background-color: #e69e00;
  border-radius: 6px;
  padding: 10px 16px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s;
  text-align: center;
  cursor: pointer;
  display: block;

  &:hover {
    transform: scale(1.05);
    background-color: #f1a602;
  }
`;

export const CTAButton = styled.button`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background-color: #e69e00;
  border-radius: 6px;
  padding: 10px 16px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s;
  text-align: center;
  cursor: pointer;
  border: none;

  &:hover {
    transform: scale(1.05);
    background-color: #f1a602;
  }
`;
