import styled from "styled-components";

export const SearchForm = styled.form`
  background: #e5e5e5;
  height: 40px;
  width: 300px;
  max-width: 100%;
  border-radius: 20px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 15px;

  button {
    border: none;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  border: none;
  font-size: 11px;
  background-color: transparent;
`;
