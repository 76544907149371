import { ListItem } from "./styles";
import { useState, useEffect } from "react";
import cookie from "js-cookie";
import Router from "next/router";

export default ({loadScreen}) => {
  // debugger;
  
  async function noImageCookie(e) {
    var value = e.target.value;
    if (cookie.get("noImage_cookie") == "true"){
      cookie.remove("noImage_cookie");
    } 
    else{
    cookie.set("noImage_cookie", value ?? "");
    }
    loadScreen(true);
    window.location.reload(false);
  }



  return (
    <>
        <ListItem>         
        <input type="checkbox" style={{cursor: 'pointer'}} id="psi" name="categoria"  onClick={noImageCookie} defaultChecked={cookie.get("noImage_cookie")} value="true"/>
        <label for="psi" style={{cursor: 'pointer'}}> Produtos sem imagem </label>
        </ListItem>
    </>
  );
}
