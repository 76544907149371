import styled, { css } from "styled-components";

export const BrandLink = styled.a`
  display: flex;
  cursor: pointer;
  place-content: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem;
  color: #000;
  display: flex;
  font-size: 18px;
  justify-content: flex-start;

`;
export const CategoryLink = styled.a`
  display: flex;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem;
  color: #000;
  display: flex;
  font-size: 14px;
  place-content: center;

`;

export const CategoryList = styled.ul`
  position: absolute;
  list-style-type: none;
  li:last-child {
    border-bottom: none;
  }
`;

export const CategoryItens = styled.li`
  a {
    color: inherit;
    text-decoration: none;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 12px;
    font-weight: 500;
  }
`;

// export const SubcategoryList = styled.ul`
//   display: none;
//   position: absolute;
//   left: 106%;
//   list-style-type: none;
//   transform: translate(127px, -61px);
//   background-color: white;
//   border: 1px solid #eee;

//   &:hover {
//     display: block;
//   }

//   li:last-child {
//     border-bottom: none;
//   }
// `;

// export const SubcategoryItem = styled.li`
//   border-bottom: 1px solid #eee;

//   &:hover {
//     // background-color: #eee;
//   }

//   a {
//     color: inherit;
//     text-decoration: none;
//     padding: 1rem;
//     display: flex;
//     align-items: center;
//     text-transform: uppercase;
//     font-size: 12px;
//     line-height: 12px;
//     font-weight: 500;
//   }
// `;

