import styled from "styled-components";

const Icon = styled.figure`
  color: ${props => props.color};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100% !important;
    height: 100% !important;
  }
`;

export default Icon;
