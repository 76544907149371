import { ListItem, Loading } from "./styles";
import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import cookie from "js-cookie";

import Router from "next/router";

// export default function OutletList() {
export default ({loadScreen}) => {
  
  async function outletCookie(e) {
    var value = e.target.value;
    
    if (cookie.get("outlet_cookie") == "true"){
      cookie.remove("outlet_cookie");
    } 
    else{
      cookie.set("outlet_cookie", value ?? "");
    }
    loadScreen(true);
    window.location.reload(false);
  }
  
  return (
    <>
        <ListItem>         
          <input type="checkbox" style={{cursor: 'pointer'}} id="outlet" name="categoria" onClick={outletCookie} defaultChecked={cookie.get("outlet_cookie")} value='true' /> 
          <label for="outlet" style={{cursor: 'pointer'}}> Outlet </label>

        </ListItem>
    </>
  );
}
