import Link from "next/link";
import { CTALink, CTAButton } from "./styles";

export default function CTA({ children, button, ...rest }) {
  return button ? (
    <CTAButton {...rest}>{children}</CTAButton>
  ) : (
    <Link {...rest} passHref>
      <CTALink>{children}</CTALink>
    </Link>
  );
}
