import styled from "styled-components";

export const ListItem = styled.li`
  color: #403e3e;
  font-size: 12px;
  padding: 10px 0;
  border-bottom: 1px dashed #cfcfcf;
  cursor: pointer;

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SubcategoryList = styled.ul`
  list-style: square inside;
  display: ${props => (props.expanded ? "block" : "none")};

  li {
    border: none;

    a {
      color: #909090;
      font-size: 11px;
      text-transform: uppercase;
      display: inline;

      &:hover {
        color: #403e3e;
      }
    }
  }
`;

