import { ListItem } from "./styles";
import { useState, useEffect } from "react";
import cookie from "js-cookie";
import Router from "next/router";

export default ({loadScreen}) => {
  // debugger;
  
  async function nacionalCookie(e) {
    var value = e.target.value;
    if (cookie.get("nacional_cookie") == "true"){
      cookie.remove("nacional_cookie");
    } 
    else{
      cookie.set("nacional_cookie", value ?? "");
    }
    loadScreen(true);
    window.location.reload(false);
  }
  async function internacionalCookie(e) {
    var value = e.target.value;
    if (cookie.get("internacional_cookie") == "true"){
      cookie.remove("internacional_cookie");
    } 
    else{
    cookie.set("internacional_cookie", value ?? "");
    }
    loadScreen(true);
    window.location.reload(false);
  }



  return (
    <>
        
        <ListItem>         
        <input type="checkbox" style={{cursor: 'pointer'}} id="CHKnacional" name="categoria"  onClick={nacionalCookie} defaultChecked={cookie.get("nacional_cookie")} value="true"/> 
        <label for="CHKnacional" style={{cursor: 'pointer'}}> Nacional </label>
          

        <input type="checkbox" style={{cursor: 'pointer'}} id="internacional" name="categoria" onClick={internacionalCookie} defaultChecked={cookie.get("internacional_cookie")} value="true"/> 
        <label for="internacional" style={{cursor: 'pointer'}}> Internacional </label>
        </ListItem>
    </>
  );
}
