import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faAngleDown, faClipboardList, faUsers, faCog } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../UserContext";

import Icon from "../Icon";
import UserDropdown from "../UserDropdown";

import { User } from "./styles";

export default function UserInfo() {
  const { user } = useUser();

  return (
    <User>
      <Link href={user ? "/carrinho" : "/login"}>
        <a>
          <Icon size="12">
            <FontAwesomeIcon icon={faUser} />
          </Icon>
          <span>{user ? user.name : "Faça seu Login"}</span>
          <Icon size="10">
            <FontAwesomeIcon icon={faAngleDown} />
          </Icon>
        </a>
      </Link>
      { user ?
        <Link  href={user && !user.ex ? "/meus-pedidos" : ""}>
          <a className="mobile-account">
            <Icon size="12">
              <FontAwesomeIcon icon={faClipboardList} />
            </Icon>
            <span>{"Meus Pedidos"}</span>
          </a>
        </Link>
        : ""}
      { user?.company_user == true ?
        <Link  href={user ? "/troca-de-tipos" : ""}>
          <a className="mobile-account">
            <Icon size="12">
              <FontAwesomeIcon icon={faUsers} />
            </Icon>
            <span>{"Alteração de perfil"}</span>
          </a>
        </Link>
        : ""}
      {(user?.is_catalog == true ) &&
        <Link  href={user ? "/gerador-catalogo" : ""}>
        <a className="mobile-account">
          <Icon size="12">
            <FontAwesomeIcon icon={faCog} />
          </Icon>
          <span>{"Catalogo"}</span>
        </a>
      </Link>
      }

      { user ? <UserDropdown></UserDropdown> : "" }
    </User>
  );
}
