import styled from "styled-components";

export const Scroll = styled.ul`
overflow-y: scroll;
height: 280px;
`;

export const ListItem = styled.li`
  color: #403e3e;
  font-size: 12px;
  padding: 10px 0;
  border-bottom: 1px dashed #cfcfcf;
  align-items: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Loading = styled.div`
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #242f3f;
  display:flex;
  justify-content: center;
  align-items: center;

  .loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    border: 4px solid #Fff;
    animation: loader 2s infinite ease;
  }
}
`;

export const ListItens = styled.li`
  color: #403e3e;
  font-size: 12px;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;


`;

export const SubcategoryList = styled.ul`
  list-style: square inside;
  display: ${props => (props.expanded ? "block" : "none")};

  border-bottom: 1px dashed #cfcfcf;
  li {
    a {
      color: #909090;
      font-size: 11px;
      text-transform: uppercase;
      display: inline;

      &:hover {
        color: #403e3e;
      }
    }
  }
`;

