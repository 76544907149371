import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList, faUsers, faCog } from "@fortawesome/free-solid-svg-icons";

import Icon from "../Icon";
import { UserDropdownItem, UserDropdownList, UserDropdownListItem } from "./styles";
import { useUser } from "../UserContext";


export default function UserDropdown() {
  const { user } = useUser();

  return (
    <div className="user-dropdown" >
      <div class="account-list-content">
        <div>
          {(user?.ex == false) &&
            (
            <a class="login" href="/meus-pedidos" rel="nofollow" title="">
              <Icon size="20">
                <FontAwesomeIcon icon={faClipboardList} />
              </Icon>
              <span>Meus pedidos</span>
            </a>)}
        </div>
      </div>
      {(user?.company_user == true) &&
        <div class="account-list-content">
          <div>
              <a class="login" href="/troca-de-tipos" rel="nofollow" title="">
                <Icon size="20">
                  <FontAwesomeIcon icon={faUsers} />
                </Icon>
                <span>Alteração de perfil</span>
              </a>
          </div>
        </div>
      }
      {(user?.is_catalog == true ) &&
        <div class="account-list-content">
          <div>
              <a class="login" href="/gerador-catalogo" rel="nofollow" title="">
                <Icon size="20">
                  <FontAwesomeIcon icon={faCog} />
                </Icon>
                <span>Catalogo</span>
              </a>
          </div>
        </div>
      }
  </div>

  );
}




