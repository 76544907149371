import styled from "styled-components";

export const Scroll = styled.ul`
overflow-y: scroll;
height: 280px;
`;

export const ListItem = styled.li`
  color: #403e3e;
  font-size: 12px;
  padding: 10px 0;
  border-bottom: 1px dashed #cfcfcf;
  align-items: center;


  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListItens = styled.li`
  color: #403e3e;
  font-size: 12px;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;


`;

export const SubcategoryList = styled.ul`
  list-style: square inside;
  display: ${props => (props.expanded ? "block" : "none")};

  border-bottom: 1px dashed #cfcfcf;
  li {
    a {
      color: #909090;
      font-size: 11px;
      text-transform: uppercase;
      display: inline;

      &:hover {
        color: #403e3e;
      }
    }
  }
`;

