import { memo } from "react";
import Link from "next/link";

import Icon from "../../Icon";

import { BrandLink, CategoryList, CategoryItens, SubcategoryList, SubcategoryItem, CategoryLink } from "./styles";
function SubMenuItem({ brand }) {
  if (brand.slug == 'natuzzi' || brand.slug == 'natuzzi-editions')
  {
    return ;
  }
  return (
    <li style={{listStyleType: 'none', marginBottom: "6px"}}>
        <Link
          href={`/linha/[...slug]`}
          as={`/linha/${brand.slug}`}
          passHref
        >
          <CategoryLink>
            <span>{brand.name}</span>
          </CategoryLink>
        </Link>
    </li>
  );
}

export default memo(SubMenuItem);
