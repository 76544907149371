import styled, { createGlobalStyle } from "styled-components";

export const DisplaySize = styled.div`
padding: 20px;

.cc-selector-2 input{
  position:absolute;
  z-index:999;
}

.a4{background-image:url(/display_a4.png);}
.a6{background-image:url(/display_a5.png);}
.tag{background-image:url(/display_a6.png);}

.cc-selector-2 input:active +.drinkcard-cc, .cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector-2 input:checked +.drinkcard-cc, .cc-selector input:checked +.drinkcard-cc{
  -webkit-filter: none;
     -moz-filter: none;
          filter: none;
}
.drinkcard-cc{
  cursor:pointer;
  background-size:contain;
  background-repeat:no-repeat;
  display:inline-block;
  width:150px;height:150px;
  -webkit-transition: all 100ms ease-in;
     -moz-transition: all 100ms ease-in;
          transition: all 100ms ease-in;
  -webkit-filter: brightness(1.8) grayscale(1) opacity(.3);
     -moz-filter: brightness(1.8) grayscale(1) opacity(.3);
          filter: brightness(1.8) grayscale(1) opacity(.3);
}
.drinkcard-cc:hover{
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
     -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
          filter: brightness(1.2) grayscale(.5) opacity(.9);
}

/* Extras */
a:visited{color:#888}
a{color:#444;text-decoration:none;}
p{margin-bottom:.3em;}
* { font-family:monospace; }
.cc-selector-2 input{ margin: 5px 0 0 12px; }
.cc-selector-2 label{ margin-left: 7px; }
span.cc{ color:#6d84b4 }
`;

export const DisplayForm = styled.div`
padding: 20px;
display: flex;
justify-content: space-around;
list-style-type: none;
li {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}
input {
  margin-right: 5px;
}
`;

export const FormSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px auto;
  max-width: 550px;

    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  p {
    margin-bottom: 20px;
    width: 100%;
  }

  a {
    color: #e69e00;
    text-decoration: none;
  }
`;
export const SpaceMobile = styled.div`
  @media (max-width: 1100px) {
    height: 110px;
  }
`;
export const MinimalSpace = styled.div`
  @media (max-width: 1100px) {
    height: 40px;
  }
`;
export const SouvSpaceMobile = styled.div`
  @media (max-width: 1100px) {
    height: 170px;
  }
`;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    background: #fff;
    color: #666;
    -webkit-font-smoothing: antialiased !important;
  }

  ul {
    list-style: none;
  }

  img {
    max-width: 100%;
  }

  svg {
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  a {
    &:hover {
      font-weight: bold;
    }
  }

`;
