import styled, { css } from "styled-components";

export const BrandLink = styled.a`
  display: flex;
  cursor: pointer;
  place-content: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem;
  color: #000;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  &:hover {


    &:hover {
  
      ${props =>
        props.hasCategory &&
        css`
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 26px 0 26px 12px;
            transition-duration: 0.5s;
            border-color: transparent transparent transparent #eee;
            position: absolute;
            right: -12px;
            top: 1px;
          }

          & ~ ul {
            display: block;
          }
        }
      `};

    }
  }

`;
export const CategoryLink = styled.a`
  display: flex;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem;
  color: #000;
  display: flex;
  font-size: 13px;
  place-content: center;


/* 
  &:hover {
    // background-color: #eee;
   ${props =>
      props.hasSubCategory &&
      css`
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 21px 0 21px 12px;
          transition-duration: 0.5s;
          border-color: transparent transparent transparent #eee;
          position: absolute;
          right: -12px;
        }

        & ~ ul {
          display: block;
        }
        & ~ li {
          display: block;
        }
      }
    `};
  } */

`;

export const CategoryList = styled.ul`
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #eee;
  transform: translate(130px, -69px);

  &:hover {
    display: block;
    
  }

  li:last-child {
    border-bottom: none;
  }
`;

export const CategoryItens = styled.li`
  border-bottom: 1px solid #eee;
  &:hover {
    // background-color: #eee;
  }

  a {
    color: inherit;
    text-decoration: none;
    padding: 1rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
`;