// export const FB_PIXEL_ID = "205940201503457"
export const FB_PIXEL_ID = "1048229382331034"
// export const FB_PIXEL_ID = "478364319167640"

export const pageview = () => {
  window.fbq('track', 'PageView')
}

export const addpaymentinfo = ({ user_token: token , cart_token: cart_token}) => {
  window.fbq('track', 'AddPaymentInfo',{
    user_token: token,
    cart_token: cart_token
  });
}

export const addtocart = (product, moeda) => {
  window.fbq('track','AddToCart',{
    content_name: product.name,
    content_type: product.categories,
    content_origin: product.origin,
    content_ids: [product.sku],
    value: product.price,
    currency: moeda
  });
}

export const purchase = (moeda, totalValue, { user_token: token, order}) => {
  window.fbq('track', 'Purchase',{
    user_token: token,
    order: order,
    value: totalValue,
    currency: moeda
  });
}

export const searchfb = (q) => {
  window.fbq('track', 'Search',{
    search_string: q
  });
}

export const viewcontent = (fetchedProduct) => {
  window.fbq('track', 'ViewContent',{
    content_name: fetchedProduct.name,
    content_ids: fetchedProduct.sku,
    content_type: 'product',
    value: fetchedProduct.price,
    currency: 'BRL'
  });
}


// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq('track', name, options)
}