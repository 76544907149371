import { useState, useEffect } from "react";

import { ListItem, SubcategoryList, Scroll, ListItens} from "./styles";
import { useRouter } from 'next/router'
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";

import { useCategories } from "../CategoriesContext";
import Icon from "../Icon";



export default ({loadScreen, CHKcategories, CHKsubCategories }) => {
  const router = useRouter()
  async function chkBox(e) {
    var value = "categories[]=" + e.target.value;
    value = value.toLowerCase();
    var firstSplit = window.location.href.split("?");
    var txt = "";
    var i;
    var finalSplite = firstSplit[1];
    var equal = false;
    finalSplite = finalSplite.split('&');
    // defaultChecked = true;

  if(finalSplite.length > 1){
    for(i = 0; i < finalSplite.length; i++){
      if(finalSplite[i] === value){
        equal = true;
      } else {
        if(i > 0 && finalSplite[i] != "") {
          txt = txt + "&" + finalSplite[i];
        } else if(finalSplite[i] != "") {
          txt = txt + finalSplite[i];       
        }  
      }
    }

    console.log(equal);


      if(!equal)
      {
        if(txt != ""){
          txt = txt + "&" + value;
        } else {
          txt = txt + value;       
        }
      }
             
      txt = "?" + txt;
        txt = txt.replace(',','');
        console.log(txt);
        router.push(txt);  
      }else{
      console.log(finalSplite);
        if(finalSplite[0] === value){
          txt= "";
        } else {
             if(finalSplite != ""){
             txt = finalSplite + "&" + value;
             } else {
              txt = txt + value;       
             }
      }

      txt = "?" + txt;
        txt = txt.replace(',','');
           router.push(txt);       
           console.log(txt);
        
    }
    loadScreen(true);
    
    }

    

    function subChkBox(e) {
      var value = "subcategories[]=" + e.target.value;
      value = value.toLowerCase();
      var txt = "";
      var i;
      if (window.location.href.indexOf ('?') > -1){
        var firstSplit = window.location.href.split("?");
        }else{
          firstSplit = window.location.href;
        }
      var finalSplite = firstSplit[1];
      var equal = false;
      finalSplite = finalSplite.split('&');
  
    if(finalSplite.length > 1){
      for(i = 0; i < finalSplite.length; i++){
        if(finalSplite[i] === value){
          equal = true;
        } else {
          if(i > 0 && finalSplite[i] != "") {
            txt = txt + "&" + finalSplite[i];
          } else if(finalSplite[i] != "") {
            txt = txt + finalSplite[i];       
          }  
        }
      }
  
      console.log(equal);
    
        if(!equal)
        {
          if(txt != ""){
            txt = txt + "&" + value;
          } else {
            txt = txt + value;       
          }
        }
               
        txt = "?" + txt;
          txt = txt.replace(',','');
          console.log(txt);
          router.push(txt);  
        }else{
        console.log(finalSplite);
          if(finalSplite[0] === value){
            txt= "";
          } else {
               if(finalSplite != ""){
               txt = finalSplite + "&" + value;
               } else {
                txt = txt + value;       
               }
        }
  
        txt = "?" + txt;
          txt = txt.replace(',','');
             router.push(txt);       
             console.log(txt);
          
      }
      loadScreen(true);
      // window.location.reload(false);
      }

  const [categoriesList, setCategoriesList] = useState([]);
  const { categories } = useCategories();

  useEffect(() => {
    const modifiedCategories = categories.map(category => {
      return { ...category, expanded: false };
    });

    setCategoriesList(modifiedCategories,);
  }, [categories]);

  const expandCategory = id => {
    const modifiedCategories = categoriesList.map(category => {
      return category.id === id
        ? { ...category, expanded: !category.expanded }
        : category;
    });

    return setCategoriesList(modifiedCategories);
  }
  
  if (CHKcategories == undefined) {
    CHKcategories = "";
  }  

  if (CHKsubCategories == undefined) {
    CHKsubCategories = "";
  }  

  return (
    
    <Scroll>
      {categoriesList.map(category => (
        <>
        <ListItem key={category.id}>
          {( CHKcategories.includes(category.slug) ) ? (
          <input type="checkbox" style={{cursor: 'pointer'}} id={category.id} onClick={chkBox} value={category.slug} defaultChecked={true}/>
          ) : (
          <input type="checkbox" style={{cursor: 'pointer'}} id={category.id} onClick={chkBox} value={category.slug} defaultChecked={false}/>
          ) }

          <label for={category.id} style={{cursor: 'pointer'}}> {category.name} </label>
             
              {(category.slug != 'bookx' && category.subcategories) && (
                <Icon
                  style={{cursor: 'pointer'}}
                  size="20"
                  color="#cfcfcf"
                  onClick={e => {
                    e.preventDefault();
                    return expandCategory(category.id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={category.expanded ? faMinusSquare : faPlusSquare}
                  />
                </Icon>
              )}

        </ListItem>
          {(category.slug != 'bookx' && category.subcategories) && (
            <SubcategoryList expanded={category.expanded}>
              {category.subcategories.map(subcategory => (
                <ListItens key={subcategory.id}>
                {( CHKsubCategories.includes(subcategory.slug) ) ? (
                  <input type="checkbox" style={{cursor: 'pointer'}} id={subcategory.id} name="categorias" onClick={subChkBox} value={category.slug + "_" + subcategory.slug} defaultChecked={true}/>
                ) : (
                  <input type="checkbox" style={{cursor: 'pointer'}} id={subcategory.id} name="categorias" onClick={subChkBox} value={category.slug + "_" + subcategory.slug} defaultChecked={false}/>
                )}

                {/* <input type="checkbox" id={subcategory.id} name="categorias" onClick={subChkBox} value={category.slug + "_" + subcategory.slug} defaultChecked={false}/> */}
                  <label for={subcategory.id} style={{cursor: 'pointer'}}> {subcategory.name} </label> 
                </ListItens>
              ))}
            </SubcategoryList>
          )}
        </>
      ))}
    </Scroll>
  );
}