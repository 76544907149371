import styled from "styled-components";

export const User = styled.div`
  a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    margin-right: 16px;
  }

  span {
    margin: 0 4px 0 8px;
    font-size: 12px;
  }
  .user-dropdown {
    display: none;
    color: #666;
    display: none;
    position: absolute;
    z-index: 9999;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.35);
    padding: 20px;
    border-radius: 3px;
    background-color: #fff;
  }

  &:hover {
    @media (min-width: 1199px) {
      .user-dropdown {
        display: block;
        position: absolute;
        z-index: 9999;
      }
    }
  }

  .mobile-account {
    display: none;

    @media (max-width: 1198px) {
        display: flex;
    }
  }
`;
